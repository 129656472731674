import { useRecoilValue } from 'recoil';
import { chatbotIdentityState } from '../../../states';

export const useChatControl = () => {
  const { identity } = useRecoilValue(chatbotIdentityState);

  return {
    user: identity.firebaseUuid,
  };
};
