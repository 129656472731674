import { memo } from 'react';
import styles from './AvatarUtterance.module.scss';
import { IMessageOpenAIAnswer } from '../../../service/type';

interface IAvatarUtteranceProps {
  data?: IMessageOpenAIAnswer;
}

export const AvatarUtterance = memo(
  ({ data }: IAvatarUtteranceProps): JSX.Element => (
    <div className={styles.container}>
      <span>{data?.params.message}</span>
    </div>
  ),
);

AvatarUtterance.displayName = 'AvatarUtterance';
