import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useChatContext } from '../../../contexts/ChatContext';
import { messageState } from '../../../states';

export const useChatMessages = () => {
  const { hideChat, reset, answerQuestion } = useChatContext();
  const messages = useRecoilValue(messageState);
  const [userMessage, setUserMessage] = useState('');
  const lastMessage = useMemo(() => messages[messages.length - 1], [messages]);
  const lastMessageType = useMemo(() => lastMessage?.instance_type, [lastMessage]);

  const handleChangeText = useCallback((value: string) => {
    setUserMessage(value);
  }, []);

  const handleSend = useCallback(() => {
    if (userMessage) {
      // eslint-disable-next-line no-console
      answerQuestion({ userMessage }).catch((e) => console.error(e));
      setUserMessage('');
    }
  }, [answerQuestion, userMessage]);

  const handleReset = useCallback(() => {
    reset()
      .then()
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e));
  }, [reset]);

  return {
    text: userMessage,
    messages,
    hideChat,
    handleReset,
    handleChangeText,
    handleSend,
    lastMessageType,
  };
};
