import styles from './ChatControl.module.scss';
import { AvatarUtterance } from '../../molecules/AvatarUtterance';
import { UserTextMessage } from '../../molecules/UserTextMessage';
import { OperatorTextMessage } from '../../molecules/OperatorTextMessage';
import { useChatControl } from './ChatControl.hook';
import { Time } from '../../molecules/Time';
import { InstanceType, TMessage } from '../../../service/type';

interface IChatControl {
  message: TMessage;
  nextMessage: TMessage;
}
/*
 * NOTE: message.instance_typeによって、表示するコンポーネントを出し分けするコンポーネント
 */
export const ChatControl = ({ message, nextMessage }: IChatControl): JSX.Element | null => {
  const { user } = useChatControl();

  switch (message.instance_type) {
    case InstanceType.text_message:
      if (message?.user_created === user) {
        return (
          <div className={styles.fadeIn}>
            <div className={styles.userTextMessage}>
              <UserTextMessage data={message} />
            </div>
            <Time currentMessage={message} nextMessage={nextMessage} user={user} />
          </div>
        );
      }
      return (
        <div className={styles.fadeIn}>
          <OperatorTextMessage data={message} />
          <Time currentMessage={message} nextMessage={nextMessage} user={user} />
        </div>
      );
    case InstanceType.bot:
      return (
        <div className={styles.fadeIn}>
          <AvatarUtterance data={message} />
          <Time currentMessage={message} nextMessage={nextMessage} user={user} />
        </div>
      );
    default:
      return null;
  }
};
